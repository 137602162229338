import React from "react"
import Layout from "../components/layout"

export default function Error() {
  return (
    <Layout blog={true}>
      <div className="full-page-module">
        <h1>Uh-Oh!</h1>
        <p>Something went wrong.</p>
      </div>
    </Layout>
  )
}